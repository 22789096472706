<template>
  <div class="qa">
    <div class="title"> 常见问题</div>
    <div class="content">
      <router-view v-if="$store.state.qaDetailsShow"></router-view>
      <div class="list" v-else>
        <div class="qusetions_item" v-for="(item,index) in list_data.lists" :key="index" @click="goDetails(item.id)">
          <div class="tilte_box">
            {{ item.title }}
          </div>
          <div class="right_arr_btn">
            <img src="../../../assets/img/icon/icon_gray_right_arr.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
  </div>
</template>
  
<script>
import mixin from '../../../mixin';
import { mapMutations } from 'vuex';
export default {
  mixins: [mixin],
  data () {
    return {
    }
  },
  created () {
    this.getData();
  },

  methods: {
    ...mapMutations(['SET_QA_DETAILS_SHOW']),
    handleChange (val) {
      console.log(val);
    },
    getData () {
      this.getList('getQuestionList', this.page);
    },
    goDetails (id) {
      console.log(id);
      this.SET_QA_DETAILS_SHOW(true);
      this.$router.push({
        path: '/personalCenter/QA/details',
        query: {
          id
        }
      })
    }
  }
}
</script>
  
<style lang="scss" scoped>
.qa {
  padding: 21px 17px 30px;
  background: #fff;
  border-radius: 8px 8px 8px 8px;

  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e5e5;
  }

  .content {
    margin: 10px 5px 5px;
    height: 650px;
    .qusetions_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 15px 0;
      border-bottom: 1px solid #ebebeb;
      &:hover {
        .tilte_box {
          color: #3f63ff;
        }
      }
      .tilte_box {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
      .right_arr_btn {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>